// About.js

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import CallToAction from "../components/Call-to-action";

const Contact = () => {
  return (
    <div className="contact-us">
      <div className="container py-2 contactss">
      
      <div className="contact-form">
      <form method="post">
            <h1 className="gradient-class mb-3 text-center">Contact Us</h1>
            <h4 className="mb-4 font-22 text-center">Please complete the form below, and we will reach out to you within the next business day.</h4>
            <div className="form-group">
                <input type="text" name="name" className="form-control" placeholder="Your Name *" required />
            </div>
            <div className="form-group">
                <input type="email" name="email" className="form-control" placeholder="Your Email *" required />
            </div>
            <div className="form-group">
                <input type="text" name="subject" className="form-control" placeholder="Subject *" required />
            </div>
            <div className="form-group">
                <textarea name="message" className="form-control" placeholder="Your Message *" rows="5" required></textarea>
            </div>
            <div className="form-group text-center d-flex" style={{justifyContent : 'center'}}>
                <button type="submit" className="btnContact animated-button menu-item text-white text-decoration-none d-block branding1 red-hover border-rounded">Send Message</button>
            </div>
        </form>
        </div>
        
       
      </div>
      <CallToAction />
    </div>
  );
};

export default Contact;

// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteRoutes from './SiteRoutes';
import Header from './components/Header'; // Update the import path
import Footer from './components/Footer';
import { CartProvider } from 'react-use-cart';

function App() {

  return (
    <Router>
      <CartProvider>
        <Header />
        <SiteRoutes />
        <Footer />
      </CartProvider>
    </Router>
  );
}

export default App;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "react-rating-stars-component";
import Faq from "../components/Faq";
import CallToAction from "../components/Call-to-action";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="terms-section py-5">
        <div className="container">
          <div className="text-center mb-5 mt-5">
            <h1 className="mb-0 text-title gradient-class">
              <span className="branding-2 font-weight-bold">
                Privacy Policy
              </span>
            </h1>
          </div>
          <div
            className="bg-white rounded-4 p-5 w-75 m-auto"
            style={{ boxShadow: "0px 0px 4px #4242421c" }}
          >
            <div className="terms-content">
              <p>This Privacy Policy describes how [Your Company Name] ("we," "us," or "our") collects, uses, and discloses information that we obtain about visitors to our website [www.hawkglide.com] (the "Site").</p>
              <ol>
                <li>
                  <strong>Information We Collect</strong>
                  <p>
                  Personal Information: When you visit our Site, we may collect certain personally identifiable information, such as your name, email address, postal address, and phone number, if voluntarily provided by you.
                  </p>
                  <p>Non-Personal Information: We may also collect non-personal information about your visit to our Site, such as your IP address, browser type, device information, and browsing behavior.</p>
                </li>

                <li>
                  <strong>Acceptance of Terms</strong>
                  <p>
                    Users are required to read and agree to these terms and
                    conditions before using Hawk Glide. By accessing or using
                    the platform, users are acknowledging their acceptance of
                    these terms.
                  </p>
                </li>

                <li>
                  <strong>User Accounts</strong>
                  <p>
                    Users may be required to create an account to access certain
                    features of Hawk Glide. Account holders are responsible for
                    maintaining the confidentiality of their account credentials
                    and for all activities that occur under their account.
                  </p>
                </li>

                <li>
                  <strong>Intellectual Property</strong>
                  <p>
                    Hawk Glide retains all rights to its intellectual property,
                    including but not limited to software, trademarks, and
                    copyrighted materials. Users are prohibited from
                    reproducing, distributing, or modifying any content or
                    materials from Hawk Glide without prior written consent.
                  </p>
                </li>

                <li>
                  <strong>Privacy Policy</strong>
                  <p>
                    Hawk Glide collects and processes personal data in
                    accordance with its Privacy Policy. By using the platform,
                    users consent to the collection, use, and disclosure of
                    their personal information as described in the Privacy
                    Policy.
                  </p>
                </li>

                <li>
                  <strong>Limitation of Liability</strong>
                  <p>
                    Hawk Glide shall not be liable for any direct, indirect,
                    incidental, consequential, or punitive damages arising out
                    of or in connection with the use of the platform, including
                    but not limited to loss of profits, data, or goodwill.
                  </p>
                </li>
                <li>
                  <strong>Indemnification</strong>
                  <p>
                    Users agree to indemnify and hold harmless Hawk Glide, its
                    affiliates, and their respective officers, directors,
                    employees, and agents from any claims, damages, or losses
                    arising out of or in connection with their use of the
                    platform.
                  </p>
                </li>

                <li>
                  <strong>Modification of Terms</strong>
                  <p>
                    Hawk Glide reserves the right to modify these terms and
                    conditions at any time without prior notice. Users are
                    responsible for reviewing these terms periodically for
                    changes. Continued use of the platform after changes are
                    made constitutes acceptance of the modified terms.
                  </p>
                </li>

                <li>
                  <strong>Governing Law</strong>
                  <p>
                    These terms and conditions shall be governed by and
                    construed in accordance with the laws of Indian Law, without
                    regard to its conflict of law provisions.
                  </p>
                </li>

                <li>
                  <strong>Intellectual Property</strong>
                  <p>
                    Hawk Glide retains all rights to its intellectual property,
                    including but not limited to software, trademarks, and
                    copyrighted materials. Users are prohibited from
                    reproducing, distributing, or modifying any content or
                    materials from Hawk Glide without prior written consent.
                  </p>
                </li>

                <li>
                  <strong>Contact Us</strong>
                  <p>
                    For questions or concerns regarding these terms and
                    conditions, please contact us at{" "}
                    <a href="mailto:support@hawkglide.com">
                      support@hawkglide.com
                    </a>
                    .
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <CallToAction />
    </>
  );
};

export default PrivacyPolicy;

// SignIn.js
/* global FB */
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import CallToAction from '../components/Call-to-action';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { toast } from 'react-toastify'

const SignIn = () => {
  const API_URL = 'https://api.hawkglide.com/api/v1';
  // const API_URL = 'http://localhost:5001/api/v1';
  const navigate = useNavigate();
  const UI_PREFIXURL = 'https://hawkglide.com';
  // const UI_PREFIXURL = 'http://localhost:3001';
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  let userDetail = secureLocalStorage.getItem('LogObject');

  const location = useLocation();

  function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
    console.log('statusChangeCallback');
    console.log(response);                   // The current login status of the person.
    if (response.status === 'connected') {   // Logged into your webpage and Facebook.
      testAPI();  
    } else {                                 // Not logged into your webpage or we are unable to tell.
      document.getElementById('status').innerHTML = 'Please log ' +
        'into this webpage.';
    }
  }


  function checkLoginState() {               // Called when a person is finished with the Login Button.
    FB.getLoginStatus(function(response) {   // See the onlogin handler
      statusChangeCallback(response);
    });
  }


  window.fbAsyncInit = function() {
    FB.init({
      appId      : '310570908760041',
      cookie     : true,                     // Enable cookies to allow the server to access the session.
      xfbml      : true,                     // Parse social plugins on this webpage.
      version    : '{api-version}'           // Use this Graph API version for this call.
    });


    FB.getLoginStatus(function(response) {   // Called after the JS SDK has been initialized.
      statusChangeCallback(response);        // Returns the login status.
    });
  };
 
  function testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function(response) {
      console.log('Successful login for: ' + response.name);
      document.getElementById('status').innerHTML =
        'Thanks for logging in, ' + response.name + '!';
    });
  }

  useEffect(() => {
    console.log('userDetail', userDetail);
    if (userDetail && userDetail.loggedin) {
      navigate('/pricing');
    }
  }, []);

  const handleSignIn = async (event) => {
    event.preventDefault();
    const email = event.target.email.value.trim();
    const password = event.target.password.value.trim();
    
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/users/login`, { userEmail: email, userPassword: password });
      setLoading(false);

      // Handle successful login
      if (response.status === 200) {
        // Perform any necessary actions upon successful login, such as redirecting to another page
        toast.success("Login successful!");
        secureLocalStorage.setItem('LogObject', {
          userID: response.data.content.userID,
          userName: response.data.content.userFirstName + ' ' + response.data.content.userSurname,
          email: email,
          loggedin: true
        });
        // Redirect to dashboard or any other page
        navigate("/pricing");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again later.");
      }
    }
  };


  const handleOAuthLogin = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${API_URL}/auth/google?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  const handleFacebookLogin = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${API_URL}/auth/facebook?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    if (action === 'login') {
      const userString = searchParams.get('user');
      const userSuccess = searchParams.get('success');
      const userFailed = searchParams.get('failed');
      if (userFailed === 'true') {
        setFailed(true);
      }
      if (userSuccess === 'true') {
        setSuccess(true);
      }
      if (userString) {
        const userObject = JSON.parse(decodeURIComponent(userString));
        setUser(userObject);
        console.log("User details from URL ====", userObject);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (failed) {
      // toast.error('Failed to login please try with valid Gmail-ID!');
      navigate('/login');
      return;
    }
    if (user) {
      console.log("user google ==========", user);
      secureLocalStorage.setItem('LogObject', {
        userID: user.userID,
        email: user.userEmail,
        loggedin: true
      });
      toast.success('Logged In successfully!');
      navigate('/checkout');
    }
    else {
      if(success && user === undefined){
        // toast.error('Failed to login please try with valid Gmail-ID!');
        navigate('/login');
      }
    }
  }, [user, failed]);

  return (
    <>
    <div className="container py-5">
      <div className='main-section-form'>

{/* <fb:login-button scope="public_profile,email" onlogin="checkLoginState();">
</fb:login-button> */}

<div id="status">
</div>

<div id="fb-root"></div>
<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0&appId=310570908760041" nonce="l2nCqkiM"></script>

<div class="fb-login-button" data-width="" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="true"></div>


<script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script>

        <div className='section-gray rounded-4 w-50 px-5 m-auto' style={{padding : '35px'}}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="mb-0 branding-2 title-text pb-2 font-weight-bold gradient-class text-center">Sign In</h1>
            <p className='fs-4 text-center branding-1'>to the Future of Marketing</p>
            <div className='links-tab py-2 my-4 mt-4 text-center d-flex justify-content-center'>
              <Link to={'/'} onClick={handleOAuthLogin} className="animated-button d-flex align-items-center">
              <div className='d-inline-block'>
              <img src={require('../images/social-inner.png')} alt="Google"  className="active-img" style={{ maxWidth: '100%', height: '24px' , marginTop : -3 }} />
              <img className="non-active-img" src={require('../images/google-social.png')} alt="Google" style={{ maxWidth: '100%', height: '24px' }} />
              </div> Signin with Google
              </Link>
              <Link to={'/'} onClick={handleFacebookLogin} className="animated-button d-flex align-items-center" style={{ marginLeft: 15 }}>
              <div className='d-inline-block'>
              <img src={require('../images/facebook-inner.png')} alt="facebook" className="active-img"  style={{ maxWidth: '100%', height: '24px' , marginTop : -3 }} /> 
              <img className="non-active-img" src={require('../images/facebook-social.png')} alt="Google" style={{ maxWidth: '100%', height: '24px' }} />
              </div>
              Signin with Facebook
              </Link>
            </div>
            <div className='d-flex justify-content-center align-items-center my-3'>
              <span className="branding-1" style={{ width: 20, marginRight: '10px', height: 2, backgroundColor: '#acacac' }}></span>
              or
              <span className="branding-1" style={{ width: 20, marginLeft: '10px', height: 2, backgroundColor: '#acacac' }}></span>
            </div>
            <Form onSubmit={handleSignIn} className="text-center">
                  <Form.Group controlId="email" className='my-4'>
                    <Form.Control type="email" placeholder="Email Address" style={{ borderRadius: 30, padding: '15px 20px' , border : 'none' }} />
                  </Form.Group>
                  <Form.Group controlId="password" className='my-4'>
                    <Form.Control type="password" placeholder="Password" style={{ borderRadius: 30, padding: '15px 20px' , border : 'none'}} />
                  </Form.Group>
                  <Button type="submit" className='animated-button' style={{ border: 'none', padding: '10px 40px' }} disabled={loading}>
                    {loading ? 'Signing In...' : 'Sign In'}
                  </Button>
                </Form>

            

            {/* Added "Forgot Password" link below the form */}
            <div className="forgot-password-link text-center my-3">
              <Link to={'/forgot-password'} className="branding-1">
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
        <div className="button-submit text-center" style={{marginTop : 20}}>
              {/* <Button style={{ border: 'none', padding: '10px 40px' }} type="submit" className='animated-button'>
                Sign In
              </Button> */}
              <p className='my-4'>Don’t have an account? <Link to={'/signup'} className="font-weight-bold branding-1 text-decoration-none">Signup</Link></p>
        </div>
        </div>
        {/* Moved "Sign In" button outside the grey section */}
        
      </div>
    </div>

    <CallToAction />
</>
  );
};

export default SignIn;

// Blog.js

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Rating from "react-rating-stars-component";
import CallToAction from "../components/Call-to-action";
import { getAllPosts } from "../data/actionMethods";

const Blog = () => {

  const navigate = useNavigate();
  const SERVER_BASE_URL = 'https://api.hawkglide.com';
  // const SERVER_BASE_URL = 'http://localhost:5001';
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    getAllPosts().then((res) => {
      setPostData(res.data.content ? res.data.content : []);
      console.log("All Post Data=====", res.data.content);
    });
  }, []);

  // Function to format the date to "16th February, 2024" format
  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-GB', options);
    return formattedDate.replace(/\d{1,2}/, (day) => {
      if (day.endsWith('1')) return day + 'st';
      if (day.endsWith('2')) return day + 'nd';
      if (day.endsWith('3')) return day + 'rd';
      return day + 'th';
    });
  };

  return (
    <>
      <div className="container py-5">
        <h1 className="title-text branding-1 text-center pt-5">
          <span className="font-weight-bold gradient-class branding-2">
            Experience the Future of Marketing with AI-Powered Tools
          </span>
          <br /> Elevate Your Social Media Strategy
        </h1>

        <section className="recent-blog-banner py-3 my-5 px-5 bg-blue-dark rounded-4">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="links-tab mb-5">
                <Link to={"/"} className="animated-button-2">
                  <span>Featured Blog Post</span>
                </Link>
              </div>
              <div className="mt-md-5 pt-5">
                <p className="text-white font-36 font-weight-bold">
                  The Power of AI: How Hawk Glide is Transforming Social Media
                  Management
                </p>
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <Link
                      to={"/blog/the-power-of-ai"}
                      className="read-more text-white text-decoration-none"
                    >
                      Read More
                    </Link>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <p className="text-white pb-0 mb-0">
                        {" "}
                        <img
                          src={require("../images/check-edit.png")}
                          alt="check-edit"
                          className="img-fluid my-3"
                        />{" "}
                        Posted on 16th February, 2024
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={require("../images/blog-banner.png")}
                alt="Blog Banner"
                className="img-fluid my-3"
              />
            </div>
          </div>
        </section>
      </div>
      <section className="service-section py-5 section-gray ">
        <div className="container">
          <h2 className="title-text gradient-class font-weight-bold pb-2">
            All Categories
          </h2>
          <div className="row my-1">
            <div className="col-md-7 px-md-3 px-0">

              <div className="row">
                {postData.map((post, index) => {
                const postMeta = JSON.parse(post.postMeta);
                const formattedDate = formatDate(post.postPublishedDate);

                return (
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on {formattedDate}
                    </p>
                    <img
                      src={`${SERVER_BASE_URL}${postMeta.featuredImage}`}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={{
                          pathname: `/blog/${post.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${post.postSlug}`);
                        }}
                        className="text-decoration-none branding-1"
                      >
                          {post.postName}
                      </Link></h5>
                      <Link
                        to={{
                          pathname: `/blog/${post.postSlug}`
                        }}
                        onClick={() => {
                          navigate(`/blog/${post.postSlug}`);
                        }}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                );
                })}
              </div>

              <div className="row">
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-md-4 my-3">
                  <div className="blog-details-listing bg-white rounded-4">
                    <p className="branding-1 mb-0 px-3 py-2 fs-6">
                      {" "}
                      <img
                        style={{ marginRight: 5 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid my-3"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                    <img
                      src={require("../images/blog-image.jpg")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                    <div className="blog-data p-3 py-4">
                      <h5><Link
                        to={"/blog/the-power-of-ai"}
                        className="text-decoration-none branding-1"
                      >
                        
                          The Power of AI: How Hawk Glide is Transforming Social
                          Media Management
                        
                      </Link></h5>
                      <Link
                        to={"/blog/the-power-of-ai"}
                        className="read-more fs-5 text-decoration-none branding-1"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>

                
              </div>
            </div>
            <div className="col-md-5 px-5">
              <div className="callto-section p-4 my-4 rounded-4">
                <img
                  style={{ width: "65px" }}
                  src={require("../images/call-hawk.png")}
                  alt="Blog Image"
                  className="img-fluid mb-3"
                />
                <p className="fs-4 font-weight-bold text-white">
                  The AI-Powered Evolution of Social Media Banners
                </p>
                <div className="links-tab py-2 my-4">
                  <Link
                    to={"/signup"}
                    className="animated-button-2"
                  >
                    <span>Start your 30-Day Free Trial</span>
                  </Link>
                </div>
              </div>

              <div className="bg-white p-4 my-4 rounded-4 quick-access">
                <p className="fs-4 font-weight-bold">Quick Access</p>

                <div
                  className="row align-items-center section-gray rounded-5 py-2 my-4"
                  style={{ margin: 0 }}
                >
                  <div className="col-md-3">
                    <img
                      style={{ width: "65px" }}
                      src={require("../images/archive.png")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9" style={{ paddingLeft: 0 }}>
                    <p className="font-weight-bold branding-1 mb-0">
                      The AI-Powered Evolution of Social Media Banners
                    </p>
                    <p className="branding-1 mb-0 py-2">
                      {" "}
                      <img
                        style={{ marginRight: 3, width: 18 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                  </div>
                </div>

                <div
                  className="row align-items-center section-gray rounded-5 py-2 my-4"
                  style={{ margin: 0 }}
                >
                  <div className="col-md-3">
                    <img
                      style={{ width: "65px" }}
                      src={require("../images/archive.png")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9" style={{ paddingLeft: 0 }}>
                    <p className="font-weight-bold branding-1 mb-0">
                      The AI-Powered Evolution of Social Media Banners
                    </p>
                    <p className="branding-1 mb-0 py-2">
                      {" "}
                      <img
                        style={{ marginRight: 3, width: 18 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                  </div>
                </div>

                <div
                  className="row align-items-center section-gray rounded-5 py-2 my-4"
                  style={{ margin: 0 }}
                >
                  <div className="col-md-3">
                    <img
                      style={{ width: "65px" }}
                      src={require("../images/archive.png")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9" style={{ paddingLeft: 0 }}>
                    <p className="font-weight-bold branding-1 mb-0">
                      The AI-Powered Evolution of Social Media Banners
                    </p>
                    <p className="branding-1 mb-0 py-2">
                      {" "}
                      <img
                        style={{ marginRight: 3, width: 18 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                  </div>
                </div>

                <div
                  className="row align-items-center section-gray rounded-5 py-2 my-4"
                  style={{ margin: 0 }}
                >
                  <div className="col-md-3">
                    <img
                      style={{ width: "65px" }}
                      src={require("../images/archive.png")}
                      alt="Blog Image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="col-md-9" style={{ paddingLeft: 0 }}>
                    <p className="font-weight-bold branding-1 mb-0">
                      The AI-Powered Evolution of Social Media Banners
                    </p>
                    <p className="branding-1 mb-0 py-2">
                      {" "}
                      <img
                        style={{ marginRight: 3, width: 18 }}
                        src={require("../images/check-edit.png")}
                        alt="check-edit"
                        className="img-fluid"
                      />{" "}
                      Posted on 16th February, 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </>
  );
};

export default Blog;

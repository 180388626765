// SignIn.js

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CallToAction from '../components/Call-to-action';

const ForgotPassword = () => {
  return (
    <>
    <div className="container py-5">
      <div className='main-section-form'>
        <div className='section-gray rounded-4 w-50 px-5 m-auto' style={{padding : '35px'}}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h1 className="mb-0 branding-2 title-text pb-2 font-weight-bold gradient-class text-center">Forgat Password</h1>
           
           
            <Form>
              <Form.Group controlId="formBasicPassword" className='my-4'>
                <Form.Control type="password" placeholder="Reset Password" style={{ borderRadius: 30, padding: '15px 20px' , border : 'none'}} />
              </Form.Group>
            </Form>

            

            {/* Added "Forgot Password" link below the form */}
            
          </div>
        </div>
        <div className="button-submit text-center" style={{marginTop : 20}}>
              <Button style={{ border: 'none', padding: '10px 40px' }} type="submit" className='animated-button'>
               Forgat Password
              </Button>
              
        </div>
        </div>
        {/* Moved "Sign In" button outside the grey section */}
        
      </div>
    </div>

    <CallToAction />
</>
  );
};

export default ForgotPassword;

// src/AppLayout.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top on route change
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

export default AppLayout;

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style.css";
import secureLocalStorage from 'react-secure-storage';

const Header = () => {
  const { pathname } = useLocation();

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isHeaderFixed, setHeaderFixed] = useState(false);
  let userDetail = secureLocalStorage.getItem('LogObject');

  console.log("userDetail header=========", userDetail);

  // Function to handle logout
  const handleLogout = () => {
    // Clear user authentication tokens or session data from SecureLocalStorage
    secureLocalStorage.removeItem('LogObject');
    // Optionally, clear other relevant data
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuItemClick = () => {
    setMobileMenuOpen(false); // Close the mobile menu when a menu item is clicked
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setHeaderFixed(true);
    } else {
      setHeaderFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log('pathname', pathname);

  function scrollToSection() {
    const section = document.getElementById('why-hawkglide');
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  }  

  return (
    <nav
      className={`navbar navbar-expand-sm navbar-light py-3 ${
        isHeaderFixed ? "fixed-top bg-white" : "position-absolute w-100"
      } ${(pathname !== "/" && pathname !== "/thankyou")}`}
    >
      {/* <nav
      className={`navbar navbar-expand-sm navbar-light py-3 ${
        isHeaderFixed ? "fixed-top bg-white" : ""
      } ${(pathname !== "/" && pathname !== "/thankyou") || isHeaderFixed ? "d-block" : "d-none"}`}
    > */}
      <div className="container w-100">
        <Link to={"/"} className="navbar-brand">
          <img
            className="logo-brand"
            src={require("../images/hawk-gliDe.png")}
            style={{ width: "260px" }}
            alt="Your Logo"
          />
        </Link>
        <button
          className="navbar-toggler p-0 mt-1"
          type="button"
          onClick={handleMobileMenuToggle}
        >
          <span className="navbar-toggler-icon">
            <span className="stripe"></span>
            <span className="stripe"></span>
            <span className="stripe"></span>
          </span>
        </button>

        <div
          className={`collapse navbar-collapse ${
            isMobileMenuOpen ? "show mobile-menu-container" : ""
          }`}
          id="navbarNav"
          style={{ justifyContent: "end" }}
        >
          {isMobileMenuOpen ? (
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <Link to={"/"} className="navbar-brand">
                  <img
                    className="logo-brand"
                    src={require("../images/hawk-gliDe.png")}
                    style={{ width: "260px" }}
                    alt="Hawk Glide Logo"
                  />
                </Link>
                <p
                  className="font-22 text-right gradient-class m-0"
                  style={{ textAlign: "right" }}
                  onClick={handleMobileMenuToggle}
                >
                  <img
                    className="logo-brand-cross"
                    src={require("../images/cross.png")}
                    alt="Close Menu"
                  />
                </p>
              </div>

              <ul className="navbar-nav ml-auto py-4 mobile-menu">
                <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/"
                    onClick={(e) => {
                      handleMenuItemClick();
                      scrollToSection();
                    }}
                  >
                    Why Hawk Glide?
                  </Link>
                </li>

                

                <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/pricing"
                    onClick={handleMenuItemClick}
                  >
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/blog"
                    onClick={handleMenuItemClick}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/about-us"
                    onClick={handleMenuItemClick}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/contact"
                    onClick={handleMenuItemClick}
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                {userDetail && userDetail.loggedin ?
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/sign-in"
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                  :
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to={"https://app.hawkglide.com"}
                    target="_blank"
                    onClick={handleMenuItemClick}
                  >
                    Sign In
                  </Link>
                  }
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="gradient-class text-decoration-none  d-flex align-items-centerfont-22"
                    to="/signup"
                    onClick={handleMenuItemClick}
                  >
                    <img
                      className="logo-brand-mobile"
                      src={require("../images/add-user.png")}
                      style={{
                        width: "32px",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      alt="Your Logo"
                    />
                    Get Started for Free
                  </Link>
                </li> */}
              </ul>

              <div className="buttons-class">
                <Link to={"/pricing"} onClick={handleMenuItemClick} className="animated-button ">
                  Start your 30-Day Free Trial
                  <img
                    style={{ width: "1.2em", marginLeft: 5, marginTop: -4 }}
                    className="hawk-white"
                    src={require("../images/hawk-white.png")}
                    alt="White Logo"
                  />
                </Link>
                <p className="pt-2"><span className="gradient-class">30 Days Free Trial.</span></p>
              </div>

              <div className="socials py-4 pt-2 pt-0 footer">
                <p className="gradient-class fs-3 font-weight-bold">
                  Follow Us
                </p>
                <a href="https://facebook.com/" target="_blank">
                  <img src={require("../images/fb-icon.png")} className="px-2" alt="Facebook Icon" />
                </a>
                <a href="https://www.instagram.com/" target="_blank" rel="noopener">
                  <img src={require("../images/insta-icon.png")} className="px-2" alt="Instagram Icon" />
                </a>
                <a href="https://www.linkedin.com/" target="_blank" rel="noopener">
                  <img src={require("../images/link-icon.png")} className="px-2 " alt="LinkedIn Icon" />
                </a>
              </div>
            </div>
          ) : (
            <ul className="unstyled list-unstyled d-flex mb-0 py-1 align-items-center">
              {/* <li className="responsive-menu-data dropdown">
                <Link
                  to={"/why-hawkglide"}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Why Hawk Glide?
                </Link>
                <div class="dropdown-menu">
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Generates AI-based Creatives</Link>
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Generates AI-based Text</Link>
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Generates AI-based Description</Link>
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Generates AI-based Hashtags</Link>
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Automatic Posts</Link>
                  <Link class="dropdown-item" to={"/generate-ai-based-creatives"}>Creative Insights</Link>
                </div>
              </li> */}

              <li className="responsive-menu-data">
                <Link
                  onClick={scrollToSection}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Why Hawk Glide?
                </Link>
              </li>

              

              <li className="responsive-menu-data">
                <Link
                  to={"/pricing"}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Pricing
                </Link>
              </li>
              <li className="responsive-menu-data">
                <Link
                  to={"/blog"}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Blog
                </Link>
              </li>
              <li className="responsive-menu-data">
                <Link
                  to={"/about-us"}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  About Us
                </Link>
              </li>
              <li className="responsive-menu-data">
                <Link
                  to={"/contact"}
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Contact Us
                </Link>
              </li>
              <li className="responsive-menu-data">
              {userDetail && userDetail.loggedin ?
              <Link
                to={"/sign-in"}
                className={
                  "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                }
                onClick={handleLogout}
              >
                Logout
              </Link>
              :
                <Link
                  to={"https://app.hawkglide.com"}
                  target="_blank"
                  className={
                    "p-2 menu-item px-4 text-black  text-decoration-none d-block branding1 red-hover"
                  }
                >
                  Sign In
                </Link>
                }
              </li>
              <li className="responsive-menu-data" style={{ marginLeft: 30 }}>
                <Link
                  style={{
                    backgroundColor: "#ff914d",
                    color: "#fff",
                    borderRadius: 35,
                  }}
                  to={"/pricing"}
                  className={
                    "animated-button menu-item text-white text-decoration-none d-block branding1 red-hover border-rounded"
                  }
                  onClick={handleMenuItemClick}
                >
                  Sign Up for 30 Days Free Trial
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;

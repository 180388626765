import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap';
import rightArrowIcon from '../images/right-arrow-icons.png'; // Replace with your image path


export default function Footer() {

  function scrollToSection() {
    const section = document.getElementById('why-hawkglide');
    if (section) {
      // Check if the current URL is the homepage
      if (window.location.pathname === '/') {
        // If on homepage, scroll directly to section
        window.scrollTo({
          top: section.offsetTop,
          behavior: 'smooth'
        })
      } else {
        // If on an inner page, redirect to homepage first
        window.location.href = '/#why-hawkglide';
      }
    }
  }
  
  return (
    
  <>
  <footer className="footer-section text-center text-lg-start text-white bg footer-bg">
<div className="container">
    <section>
      <div className="text-center text-md-start">
        {/* Grid row */}
        <div className="row">
          {/* Grid column */}
          <div className="col-md-4 col-lg-4 col-xl-4 p-0">
            
            <img src={require('../images/white-logo-hg.png')} className='mb-5' style={{width: '50%'}}/>
            <p class="text-white">
            Made with ♥ in India!<br/>
            2024 HawkGlide.com | All rights reserved.<br/>
            <strong>Need help?</strong> Reach us at: <a class="text-decoration-none" href="mailto:hello@hawkglide.com" style={{color: "#EE4266"}}>hello@hawkglide.com</a>
            </p>
           
           <p className='mt-5 mb-0 text-white'><strong>Official Channels</strong></p>
            <div className='socials pt-3 pb-4 footer'>
              <a href="https://facebook.com/" target="_blank"><img style={{width:'37px'}} src={require('../images/facebook.png')} className="pe-2" /></a>
              <a href="https://twitter.com/" target="_blank"><img style={{width:'44px'}} src={require('../images/twitter.png')} className="px-2" /></a>
              <a href="https://www.instagram.com/" target="_blank" rel="noopener"><img style={{width:'44px'}} src={require('../images/instagram.png')} className="px-2" /></a>
              <a href="https://www.linkedin.com/" target="_blank" rel="noopener"><img style={{width:'44px'}} src={require('../images/linkedin.png')} className="px-2 " /></a>
              <a href="https://www.youtube.com/" target="_blank" rel="noopener"><img style={{width:'44px'}} src={require('../images/youtube.png')} className="px-2 " /></a>

            </div>
          </div>
          {/* Grid column */}

          {/* Grid column */}
          <div className="col-md-4 col-lg-4 col-xl-4 mx-auto text-center mt-md-0 mt-3">
          <p className="text-white mb-4"><strong>Company</strong></p>
            
            <p><Link to={"/faq"} className="text-white text-decoration-none">Frequently Asked Questions</Link></p>
            <p><Link to={"/"} onClick={scrollToSection} className="text-white text-decoration-none">Why Hawk Glide?</Link></p>
            <p><Link to={"/pricing"} className="text-white text-decoration-none">Pricing</Link></p>
            <p><Link to={"/blog"} className="text-white text-decoration-none">Blog</Link></p>
            {/* <p><Link to={"/agencies"} className="text-white text-decoration-none">Agencies</Link></p> */}
            {/* <p><Link to={"/careers"} className="text-white text-decoration-none">Careers</Link></p> */}
          </div>
          {/* Grid column */}
          {/* Grid column */}
          <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mt-md-0 mt-3 position-relative text-md-end text-center">
            <p className="text-white mb-4"><strong>Resources</strong></p>

            <p><Link to={"/terms-and-conditions"} className="text-white text-decoration-none">Terms & Conditions</Link></p>
            <p><Link to={"/privacy-policy"} className="text-white text-decoration-none">Privacy Policy</Link></p>
            <p><Link to={"/refund-policy"} className="text-white text-decoration-none">Refund Policy</Link></p>
            <div className='text-md-end text-center mt-5'>
              <div className="footer-link text-white">For support and refund-related <br/>inquiries, please write to us at<br/><span className="footer-support-span"> <a className='text-decoration-none' href="mailto:hello@hawkglide.com" style={{color: "#EE4266"}}>hello@hawkglide.com</a></span></div>
            </div>
          </div>
          {/* Grid column */}
        </div>
        
      </div>
    </section>
    {/* Section: Links  */}
    {/* Copyright */}
    {/* Grid row */}
    </div>

  </footer>
  </>

  )
}

// Checkout.js

import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button } from 'react-bootstrap';
import Rating from 'react-rating-stars-component';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify'


const StarRating = ({ rating }) => {
    return (
      <Rating
        count={5}
        value={rating}
        size={24}
        activeColor="#ffd700"
        isHalf={true}
        edit={false}
      />
    );
  };

const SignUp = () => {

  const navigate = useNavigate();
  const API_URL = 'https://api.hawkglide.com/api/v1';
  // const API_URL = 'http://localhost:5001/api/v1';
  const UI_PREFIXURL = 'https://hawkglide.com';
  // const UI_PREFIXURL = 'http://localhost:3001';
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  let userDetail = secureLocalStorage.getItem('LogObject');

  const location = useLocation();
  // const cart = location.state && location.state.cart ? location.state.cart : [];


  console.log("user=========", user);

  const [userSignUpData, setUserSignUpData] = useState({
    userFullName: "",
    userEmail: "",
    userPassword: "",
    agreement: false,
    userMeta: {
      userRole: "Sign Up from HawkGlide Website",
    },
  });

  useEffect(() => {
    console.log('userDetail', userDetail);
    if (userDetail && userDetail.loggedin) {
      navigate('/checkout');
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "userFullName") {
      const fullNameParts = value.split(" ");
      const firstName = fullNameParts[0] || "";
      const surname = fullNameParts.slice(1).join(" ") || "";

      setUserSignUpData({
        ...userSignUpData,
        userFullName: value,
        userFirstName: firstName,
        userSurname: surname,
      });
    } else {
      setUserSignUpData({ ...userSignUpData, [name]: value });
    }
  };

  // const location = useLocation();
  const cartFromLocationState = location.state && location.state.cart;

  const [cart, setCart] = useState([]);

  // Load cart data from localStorage on component mount
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart'));
    if (storedCart) {
      setCart(storedCart);
    }
  }, []);

  // Update the cart state whenever the cart data changes
  useEffect(() => {
    if (cartFromLocationState) {
      setCart(cartFromLocationState);
      // Save cart data to localStorage whenever it changes
      localStorage.setItem('cart', JSON.stringify(cartFromLocationState));
    }
  }, [cartFromLocationState]);

  const handleSignUp = async () => {
    try {
      const { userFirstName, userSurname, userEmail, userPassword } = userSignUpData;

      if (!userFirstName || !userSurname || !userEmail || !userPassword) {
        toast.error("Please fill in all the required fields.");
        return;
      }

      const response = await axios.post(`${API_URL}/users/signup`, userSignUpData);

      if (response.status === 200) {
        console.log("Signup Response==========", response);
        toast.success("Sign-up successful! You can now proceed to checkout.");
        secureLocalStorage.setItem('LogObject', {
          userID: response.data.content.userID,
          email: userSignUpData.userEmail,
          name: userSignUpData.userFullName,
          loggedin: true
        });
        localStorage.setItem('cart', JSON.stringify(cart));
        navigate("/checkout");
      } else if (response.status === false) {
        toast.error(response.msg);
      }
    } catch (error) {
      console.error("Sign-up failed:", error);
      toast.error("Sign-up failed. Please try again later.");
    }
  };
  

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script')

      script.src = src

      script.onload = () => {
        resolve(true)
      }

      script.onerror = () => {
        resolve(false)
      }

      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js')
  })

  const handleOAuthLogin = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${API_URL}/auth/google?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  const handleFacebookLogin = () => {
    const encodedSite = encodeURIComponent(UI_PREFIXURL);
    const oauthUrl = `${API_URL}/auth/facebook?UI_PREFIXURL=${encodedSite}`;
    window.location.href = oauthUrl; // Use window.location.href to perform the redirect
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const action = searchParams.get('action');
    if (action === 'login') {
      const userString = searchParams.get('user');
      const userSuccess = searchParams.get('success');
      const userFailed = searchParams.get('failed');
      if (userFailed === 'true') {
        setFailed(true);
      }
      if (userSuccess === 'true') {
        setSuccess(true);
      }
      if (userString) {
        const userObject = JSON.parse(decodeURIComponent(userString));
        setUser(userObject);
        const userFullName = userObject.userFirstName + ' ' + userObject.userSurname;
        secureLocalStorage.setItem('LogObject', {
          userID: userObject.userID,
          email: userObject.userEmail,
          name: userFullName,
          loggedin: true
        });
        console.log("User details from URL ====", userObject);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (failed) {
      // toast.error('Failed to login please try with valid Gmail-ID!');
      navigate('/login');
      return;
    }
    if (user) {
      console.log("user google ==========", user);
      secureLocalStorage.setItem('LogObject', {
        userID: user.userID,
        email: user.userEmail,
        loggedin: true
      });
      toast.success('Logged In successfully!');
      navigate('/checkout');
    }
    else {
      if(success && user === undefined){
        // toast.error('Failed to login please try with valid Gmail-ID!');
        navigate('/login');
      }
    }
  }, [user, failed]);

  return (
    <>

      <div className="container py-5">
        <div className='main-section-form'>
          
            <div className="row justify-content-center align-items-center">
            <div className="col-md-3 bg-white p-4 rounded-4" style={{opacity : 0.3, filter: 'blur(2px)',boxShadow : 'rgb(200, 200, 200) 0px 0px 2px' , marginRight : -50}} >
                    <div>
                    <img src={require('../images/seccret-room.png')} alt="Product 1" className='pb-2' style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div>
                    
                    <p>My online presence has never been stronger. Highly recommend!</p>
                    <p className='font-weight-bold my-0'>Sarah Johnson</p>
                    <p className='fs-6 my-0'>Founder Secret Room</p>
                    <StarRating rating={5} />
                    </div>
                </div>
              <div className="col-md-6 position-relative z-index" style={{zIndex : 99}}>
              <div className='section-gray rounded-4 w-100 px-4 m-auto' style={{ padding: '35px' }}> 
                <h1 className="mb-0 branding-2 title-text pb-2 gradient-class font-weight-bold text-center">#1 most used<br/>
AI tool for Social Media Marketing</h1>
                <p className='fs-3 text-center branding-1'>Signup for 30 Days Free Trial</p>
                <div className='links-tab py-2 my-4 mt-4 text-center'>
              <Link to={'/'} onClick={handleOAuthLogin} className="animated-button">
              <img src={require('../images/social-inner.png')} alt="Google" style={{ maxWidth: '100%', height: '24px' , marginTop : -3 }} /> Signup with Google
              </Link>
              <Link to={'/'} onClick={handleFacebookLogin} className="animated-button" style={{ marginLeft: 15 }}>
              <img src={require('../images/facebook-inner.png')} alt="facebook" style={{ maxWidth: '100%', height: '24px' , marginTop : -3 }} /> Signup with Facebook
              </Link>
            </div>
            <div className='d-flex justify-content-center align-items-center my-3'>
              <span className="branding-1" style={{ width: 20, marginRight: '10px', height: 2, backgroundColor: '#acacac' }}></span>
              or
              <span className="branding-1" style={{ width: 20, marginLeft: '10px', height: 2, backgroundColor: '#acacac' }}></span>
            </div>
                <Form>
                <Form.Group controlId="formBasicFullName" className='my-4'>
          <Form.Control type="text" value={userSignUpData.userFullName} onChange={handleInputChange} name="userFullName" placeholder="Full Name" style={{ borderRadius: 30, padding: '15px 20px', border: 'none' }} />
        </Form.Group>

        <Form.Group controlId="formBasicEmail" className='my-4'>
          <Form.Control type="email" value={userSignUpData.userEmail} onChange={handleInputChange} name="userEmail" placeholder="Email Address" style={{ borderRadius: 30, padding: '15px 20px', border: 'none' }} />
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className='my-4'>
          <Form.Control type="password" value={userSignUpData.userPassword} onChange={handleInputChange} name="userPassword" placeholder="Password" style={{ borderRadius: 30, padding: '15px 20px', border: 'none' }} />
        </Form.Group>
                </Form>

                {/* Updated text for registration agreement */}
                <div className="registration-agreement text-center my-3">
                  By registering, you agree to our <Link to={'/terms-and-condition'} className="branding-1 font-weight-bold">terms of use</Link>
                </div>
                {/* <div class="text-center">
                    <h2>Your Shopping Cart</h2>
                    <ul>
                        {cart.map((item) => (
                        <li key={item.id}>{item.item.name} - {item.item.amount / 100}</li>
                        ))}
                    </ul>
                </div> */}
                <div className="button-submit text-center" style={{ marginTop: 20 }}>
            <Button style={{ border: 'none', padding: '10px 40px' }} type="submit" onClick={handleSignUp} className='animated-button'>
              Start your 30-Day Free Trial
            </Button>

            <p className='my-4'>Already have an account? <Link to={"https://app.hawkglide.com"} target="_blank" className="font-weight-bold branding-1 text-decoration-none">Login</Link></p>
          </div>
              </div>
              </div>
              <div className="col-md-3 bg-white p-4 rounded-4" style={{opacity : 0.3, filter: 'blur(2px)', boxShadow : 'rgb(200, 200, 200) 0px 0px 2px' , marginLeft : -50}}>
                    <div>
                    <img src={require('../images/shubamMotivala.png')} alt="Product 2" className='pb-2' style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    <div>
                    
                    <p>My online gfdgdf has never been stronger. Highly recommend!</p>
                    <p className='font-weight-bold my-0'>Khushbu Agarwal</p>
                    <p className='fs-6 my-0'>COO - Shubham Motiwala</p>
                    <StarRating rating={5} />
                    </div>
                </div>
            </div>
            
          </div>

          {/* Updated "Start your 30-Day Free Trial" button */}
          
        </div>
      
        <section className="callto-section py-5 bg-branding-2">
        <div className="container">
          {/* <div className="row align-items-center justify-content-center"> */}
            {/* Text on the left side */}
            <div className="w-63 m-auto text-center">
              <div className="text-container">
                <h3 className="title-text fs-1 py-2 mb-3 text-white">
                  <span className="font-weight-bold ">
                    Powerful AI tool for Social Media Management
                  </span>{" "}
                  that increases your Social Media Presence
                </h3>
                <div className="links-tab py-2 my-4">
                  <Link
                    to={"/"}
                    className="animated-button-2 bg-transparent border-white text-white"
                  >
                    Start your 30-Day Free Trial
                  </Link>
                  <Link
                    to={"/"}
                    className="animated-button-2 bg-transparent border-white text-white"
                    style={{ marginLeft: 15 }}
                  >
                    See Pricing Plans
                  </Link>
                </div>
                {/* Add any other text or components as needed */}
              </div>
            </div>

            {/* Image on the right side */}
            <div className="col-md-4 text-center">
              <div className="image-container">
                {/* <img
                    src={require('../images/wings.jpg')}
                    alt="Wings Image"
                    className="img-fluid" style={{height: 200, objectFit : 'contain'}}
                /> */}
              </div>
            </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default SignUp;

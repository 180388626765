import axios from 'axios';

const API_URL = 'https://api.hawkglide.com/api/v1';
// const API_URL = 'http://localhost:5001/api/v1';

// export async function getAllPlans() {
//     return axios
//       .get(`${API_URL}/razorpay/fetch-plans`)
//       .then((res) => res)
//       .catch((err) => err);
// }

export async function getAllPlans() {
  return axios
    .get(`${API_URL}/plans`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getAllPosts() {
  return axios
    .get(`${API_URL}/blogPosts`)
    .then((res) => res)
    .catch((err) => err);
}

export async function getPostByID(postID) {
  console.log("PostID=====", postID);
  return axios
    .get(`${API_URL}/blogPosts/single-post?postID=${postID}`)
    .then((res) => res)
    .catch((err) => err);
}